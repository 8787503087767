<template>
  <v-app id="login">
    <v-main>
      <v-container class="section-container" fluid fill-heigh>
        <Loader :loading="loading" />
        <v-row class="signin">
          <v-col cols="0" md="8" class="left" v-if="!$vuetify.breakpoint.mobile">
            <v-img width="100vw" class="animate__animated animate__bounceInLeft" max-height="100vh" :src="mainImg" aspect-ratio="1" />
          </v-col>
          <v-col cols="12" md="4" class="right text-center">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="4" class="itemsCenter">
                <v-img max-width="100px" class="animate__animated animate__bounceInLeft img-shadow" :src="logo" max-height="130" contain />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <h2 class="marginBottom marginTop">Notaría Pública No. 51</h2>
            <h2 class="marginBottom marginTop">Sistema Reloj Checador</h2>
            <h2 class="marginTop">Inicio de sesión</h2>
            <validation-observer ref="observer">
              <v-form @submit.prevent="submit">
                <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                  <v-text-field v-model="form.username" :error-messages="errors" label="Email" required outlined dark filled dense></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="Correo" rules="required" v-if="!resetMode">
                  <v-text-field v-model="form.password" :error-messages="errors" label="Contraseña" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass" required outlined dense dark filled :type="showPass ? 'text' : 'password'"></v-text-field>
                </validation-provider>
                <div class="text-center">
                  <v-btn class="signin-btn" type="submit" rounded color="white" dark> {{resetMode ? 'Recuperar' : 'Entrar'}}  </v-btn>
                  <br />
                  <v-btn text @click="recoverPassword()" color="white"> {{resetMode ? 'Volver' : 'Olvide mi contraseña'}}</v-btn>
                </div>
              </v-form>
            </validation-observer>
            <v-row>
              <v-col cols="1" class="text-left">
                <a class="animate__animated animate__bounceInUp itemsBottom relocate" href="http://notaria51.com" target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="3">
                <span class="animate__animated animate__bounceInUp itemsBottom" v-text="'V' + version" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import "animate.css";
import { Loader, BasicAlert } from "@/components";
import { required, email } from "vee-validate/dist/rules";
import { extend, ValidationProvider, setInteractionMode, ValidationObserver } from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} no debe estar vacio",
});

extend("email", {
  ...email,
  message: "El email no es valido",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loader,
    BasicAlert,
  },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    host: process.env.VUE_APP_API_SERVER,
    email: "",
    password: null,
    showPass: false,
    loading: false,
    resetMode: false,
    mainImg: require("@/assets/images/main.jpeg"),
    logo: require("@/assets/images/logo_c.png"),
    form: {
      username: "",
      password: "",
    },
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    paths: {
      login: "/pub/login",
      recover: "/pub/recover/password",
    },
  }),
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    async submit() {
      if (this.resetMode) {
        this.reset();
      } else {
        this.login();
      }
    },
    async login() {
      let valid = await this.$refs.observer.validate();
      if (valid) {
        this.loading = true;
        let data = {
          username: this.form.username,
          password: this.form.password,
        };
        this.axios
          .post(this.host + this.paths.login, { data })
          .then((response) => {
            let res = response.data;
            this.$session.start();
            this.$session.set("jwt", res.token);
            this.redirectPage();
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (error.response) {
              this.alert.msg = error.response.data.message;
            } else {
              this.alert.msg = "Servicio no disponible. Intente más tarde";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    async reset() {
      let valid = await this.$refs.observer.validate();
      if (valid) {
        this.loading = true;
        let data = {
          email: this.form.username,
        };
        this.axios
          .post(this.host + this.paths.recover, { data })
          .then(() => {
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Recibirá un correo con las instrucciones para recuperar su contraseña";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (error.response) {
              this.alert.msg = error.response.data.message;
            } else {
              this.alert.msg = "Servicio no disponible. Intente más tarde";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "success";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    clear() {
      this.email = "";
      this.password = null;
      this.$refs.observer.reset();
    },
    recoverPassword() {
      this.resetMode = !this.resetMode;
    },
    redirectPage() {
      let params = {}
      if (this.$route.query && Object.keys(this.$route.query).length) {
        for (let key in this.$route.query) {
          params[key] = this.$route.query[key]
        }
      }
      this.$router.push({
        name: 'Home',
        params: params
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.itemsBottom {
  position: absolute;
  bottom: 0;
}
.relocate {
  color: #fff;
  text-decoration: none;
}
.marginTop {
  margin-top: 0% !important;
}
.marginBottom {
  margin-bottom: 0% !important;
}
.section-container {
  margin: 0px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 1px 1px rgba($color: #000000, $alpha: 0.1);
  box-sizing: border-box;
  .signin {
    min-height: 100vh;

    .left {
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      display: flex;
      padding: 0;

      color: #6c1414;
      background-color: #f9f9f9;
    }
    .right {
      box-sizing: border-box;
      background: #6c1414;
      color: #fff;
      h2 {
        text-align: center;
        margin: 30px 0;
      }
      .signin-btn {
        width: 100%;
        color: #6c1414;
      }
    }
  }
}
</style>
